
.main-nav {
  width: 100%;
  display: grid;
  padding: 5px 35px;
}

.logo {
  display: flex;
}

.menu-link {
  grid-column: 3/4;
}

.menu-link ul {
  /* height: 10rem; */
  display: flex;
  justify-content: space-around;
  align-items: center;
}


/* ----------- Grid part ends ----------------- */

.logo img {
  width: 60px;
  height: 60px;
}

.menu-link{
  margin-top: 20px;
}

.menu-link ul{
  list-style: none;
}

.menu-link ul li a {
  text-transform: capitalize;
  color: #000;
  text-decoration: none;
  font-size: .95rem;
  margin-left: -18px;
}
.menu-link ul li a:hover{
  padding-bottom: 10px;
  border-bottom: 2px solid #ffc200;
}
/* .menu-link ul li:hover > a {
  transform-origin: left;
  color: rgba(0, 0, 3, 1);
  text-decoration: underline;
  transition: 0.5s;
} */

.social-media .hamburger-menu {
  display: none;
}

.social-media .hamburger-menu a{
  color: #ffc200;
}

/* responsive css style  */
@media (max-width: 1080px) {


  .logo,
  .menu-link ul,
  .social-media ul {
    height: 2rem;
  }
}

/* responsive css style  */
@media (max-width: 998px) {
  .logo, .social-media ul {
    margin-top: 6px;
}
   .main-nav {
    height: 5rem;
    grid-template-columns: 2rem 2fr 3fr 2rem 2rem;
  }

  .menu-link {
    display: none;
  }

  .mobile-menu-link {
    grid-column: 2/4;
    position: relative;
    z-index: 99;
  }

  .mobile-menu-link {
    background-color: #fff;
    height: 26rem;
    display: grid;
    /* top: 0 !important; */
    /* border-radius: 15px; */
    /* grid-column: 2 / 5; */
    align-items: center;
    padding-left: 3.5rem;
    transition: all 2s linear;
    margin-top: -3.2rem;
    margin-left: -4rem;
    transform-origin: top;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

  .mobile-menu-link ul {
    height: 26rem;
    padding: 15px 0;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: start;
  }

  .mobile-menu-link ul li{
    margin-bottom: 10px;
  }

  .mobile-menu-link ul li:first-child {
    transition-delay: 0.2s;
  }

  .social-media {
    grid-row: 1/2;
    grid-column: 3/5;
    justify-items: end;
    align-items: center;
    transition: all 2s linear;
  }

  .social-media .social-media-desktop {
    height: 0;
    display: none;
  }

  .social-media {
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }
}

@media (max-width: 798px) {
  .main-nav {
    height: 5rem;
    grid-template-columns: 1rem 2fr 1fr 1fr 1rem;
  }


  .social-media {
    height: 4.5rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }
  .mobile-menu-link {
    background-color: #fff;
    height: 26rem;
    display: grid;
    /* top: 0 !important; */
    /* border-radius: 15px; */
    /* grid-column: 2 / 5; */
    align-items: center;
    padding-left: 3.5rem;
    transition: all 2s linear;
    margin-top: -3.2rem;
    margin-left: -4rem;
    transform-origin: top;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
}

@media (max-width: 520px) {
  .logo, .social-media ul {
    margin-top: 6px;
}
  .main-nav {
    height: 5rem;
    grid-template-columns: 1rem 4fr 1fr 1fr 1rem;
  }



  .social-media {
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .social-media .hamburger-menu {
    display: block;
    font-size: 2.5rem;
  }
  .mobile-menu-link {
    background-color: #fff;
    height: 26rem;
    display: grid;
    /* top: 0 !important; */
    /* border-radius: 15px; */
    /* grid-column: 2 / 5; */
    align-items: center;
    padding-left: 3.5rem;
    transition: all 2s linear;
    margin-top: -3.2rem;
    margin-left: -4rem;
    transform-origin: top;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
  /* hero section  */

}
