.coupon_main{
  margin-top: 50px;
  padding: 50px 0;
}

.coupon_heading h1{
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
  color: #000;
}

.coupon_bottom{
  display: flex;
  padding: 40px 34px;
}

.coupon_bottom_sub{
  display: flex;
  /* margin: 0 10px; */
}

.coupon_bottom_sub a{
  text-decoration: none;
}

.coupon_bottom_sub .coupon{
  margin: 0 15px;
  border: solid 1px #f4f4f4;
  flex-direction: column;
  padding: 28px 30px 35px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.03);
  transition: 0.5s;
  width: 311px;
  height: 254px;
}

.coupon_logo h2{
  font-size: 23px;
  color: #000;
}

.coupon_bottom_sub .coupon:hover{
  border: 1.5px solid #ffc100;
}

.coupon .title_coupon h3{
  color: #000;
  font-size: 15px;
  margin-top: 20px;
  line-height: 25px;
  margin-bottom: 20px;
}

.coupon .title_coupon p{
  color: #ffc100;
  margin-top: -14px;
}

.view_all{
  text-align: center;
  margin-top: 60px;
}

.view_all a{
  text-decoration: none;
}

.View_all_btn{
  color: #000;
  background: #ffcc01;
  padding: 10px 20px;
  border-radius: 5px;
}

.view_all_link{
  text-decoration: none;
}



@media screen and (min-width: 1000px) and (max-width: 1200px){
  .coupon_heading h1{
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 1px;
    margin-left: -15px;
    text-align: center;
    color: #000;
  }

  .coupon_main{
    margin-top: 50px;
    padding: 50px 0;
  }

  .coupon_bottom{
    display: block;
    padding: 40px 120px;
  }

  .coupon_bottom_sub{
    display: flex;
    margin: 0 60px;
    padding: 0 0px;
    margin-left: 20px;
  }

  .coupon_bottom_sub a{
    text-decoration: none;
  }

  .coupon_bottom_sub .coupon{
    margin: 25px 35px;
    border: solid 1px #f4f4f4;
    flex-direction: column;
    padding: 28px 30px 35px;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.03);
    transition: 0.5s;
    width: 311px;
    height: 314px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

}

@media screen and (max-width: 599px){
  .coupon_main{
    margin-top: 50px;
    padding: 50px 0;
  }

  .coupon_heading h1{
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    text-align: center;
    color: #000;
  }

  .coupon_bottom{
    display: block;
    /* padding: 40px 53px; */
  }

  .coupon_bottom_sub{
    display: flex;
    /* margin: 0 10px; */
  }

  .coupon_bottom_sub a{
    text-decoration: none;
  }

  .coupon_bottom_sub .coupon{
    margin: 0 15px;
    border: solid 1px #f4f4f4;
    /* flex-direction: column; */
    padding: 28px 30px 35px;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.03);
    transition: 0.5s;
    width: 191px;
    height: 184px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
  }

  .coupon_logo h2{
    color: #000;
    font-size: 18px;
  }

  .coupon_bottom_sub .coupon:hover{
    border: 1.5px solid #ffc100;
  }

  .coupon .title_coupon h3{
    color: #000;
    font-size: 10px;
    margin-top: 10px;
    line-height: 15px;
    margin-bottom: 20px;
  }

  .coupon .title_coupon p{
    font-size: 10px;
    color: #ffc100;
    margin-top: -14px;
  }

  .view_all{
    text-align: center;
  }

  .view_all a{
    text-decoration: none;
  }

  .View_all_btn{
    color: #000;
    background: #ffcc01;
    padding: 10px 20px;
    border-radius: 5px;
  }

  .view_all_link{
    text-decoration: none;
  }

}

@media screen and (min-width: 600px) and (max-width: 750px){
  .coupon_main{
    margin-top: 50px;
    padding: 50px 0;
  }

  .coupon_heading h1{
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    text-align: center;
    color: #000;
  }

  .coupon_bottom{
    display: block;
    margin: 40px 50px;
  }

  .coupon_bottom_sub{
    display: flex;
    /* margin: 0 10px; */
  }

  .coupon_bottom_sub a{
    text-decoration: none;
  }

  .coupon_bottom_sub .coupon{
    margin: 0 15px;
    border: solid 1px #f4f4f4;
    /* flex-direction: column; */
    padding: 28px 30px 35px;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.03);
    transition: 0.5s;
    width: 251px;
    height: 204px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 40px;
  }

  .coupon_logo h2{
    color: #000;
  }

  .coupon_bottom_sub .coupon:hover{
    border: 1.5px solid #ffc100;
  }

  .coupon .title_coupon h3{
    color: #000;
    font-size: 10px;
    margin-top: 20px;
    line-height: 15px;
    margin-bottom: 20px;
  }

  .coupon .title_coupon p{
    color: #ffc100;
    margin-top: -14px;
  }

  .view_all{
    text-align: center;
  }

  .view_all a{
    text-decoration: none;
  }

  .View_all_btn{
    color: #000;
    background: #ffcc01;
    padding: 10px 20px;
    border-radius: 5px;
  }

  .view_all_link{
    text-decoration: none;
  }

}
@media screen and (min-width: 751px) and (max-width: 998px){
  .coupon_main{
    margin-top: 50px;
    padding: 50px 0;
  }

  .coupon_heading h1{
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    text-align: center;
    color: #000;
  }

  .coupon_bottom{
    display: block;
    margin: 40px 0px;
  }

  .coupon_bottom_sub{
    display: flex;
    margin: 0 0px;
  }

  .coupon_bottom_sub a{
    text-decoration: none;
  }

  .coupon_bottom_sub .coupon{
    margin: 0 15px;
    border: solid 1px #f4f4f4;
    /* flex-direction: column; */
    padding: 28px 30px 35px;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.03);
    transition: 0.5s;
    width: 311px;
    height: 254px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 40px;
  }

  .coupon_logo h2{
    color: #000;
  }

  .coupon_bottom_sub .coupon:hover{
    border: 1.5px solid #ffc100;
  }

  .coupon .title_coupon h3{
    color: #000;
    font-size: 13px;
    margin-top: 20px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .coupon .title_coupon p{
    color: #ffc100;
    margin-top: -14px;
  }

  .view_all{
    text-align: center;
  }

  .view_all a{
    text-decoration: none;
  }

  .View_all_btn{
    color: #000;
    background: #ffcc01;
    padding: 10px 20px;
    border-radius: 5px;
  }

  .view_all_link{
    text-decoration: none;
  }

}