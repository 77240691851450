.categories_main{
  margin-top: 50px;
  padding: 50px 0;
  background: #fff;
}

.cate_all_page{
  margin-top: 10px !important;
  background: #fff;
}

.categories_heading h1{
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
  color: #000;
}

.categories_bottom{
  display: flex;
  padding: 40px 15px;
  justify-content: space-between;
}

.categories_bottom_sub{
  display: flex;
  padding: 0 0px;
}

.categories_bottom_sub .categories{
  margin: 0 30px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.03);
  border: solid 1px #f4f4f4;
  transition: 0.5s;
  padding: 10px;
}

.categories_bottom_sub .categories:hover{
  border: 1.5px solid #ffcc01;
}

.categories img{
  width: 150px;
  height: 105px;
  object-fit: cover;
}

.view_all{
  text-align: center;
  margin-top: 60px;
}

.view_all a{
  text-decoration: none;
}

.View_all_btn{
  color: #000;
  background: #ffcc01;
  padding: 10px 20px;
  border-radius: 5px;
}

.view_all_link{
  text-decoration: none;
}



@media screen and (min-width:600px) and (max-width: 750px){
  .categories_main{
    padding-top: 30px;
    /* padding: 50px 0; */
  }

  .categories_heading h1{
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1.5px;
    text-align: center;
    color: #000;
  }

  .categories_bottom{
    display: block;
    padding: 15px 15px;
    justify-content: none;

  }

  .categories_bottom_sub{
    display: flex;
    padding: 0 0px;
    margin-left: 20px;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .categories_bottom_sub .categories{
    padding: 20px 0px;
    margin-left: 0px;
    margin-top: 10px;
    width: 220px;
    height: 200px;
  }
  .categories img{
    width: 200px;
    height: 150px;
    object-fit: cover;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1200px){
  .categories_main{
    padding-top: 30px;
    /* padding: 50px 0; */
  }

  .categories_heading h1{
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1.5px;
    text-align: center;
    color: #000;
  }

  .categories_bottom{
    display: block;
    padding: 15px 15px;
    justify-content: none;

  }

  .categories_bottom_sub{
    display: flex;
    padding: 0 0px;
    margin-left: 20px;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .categories_bottom_sub .categories{
    padding: 20px 0px;
    margin-left: 0px;
    margin-top: 10px;
    width: 370px;
    height: 230px;
  }
  .categories img{
    width: 270px;
    height: 200px;
    object-fit: cover;
  }
}

@media screen and (min-width: 751px) and (max-width: 998px){
  .categories_main{
    padding-top: 30px;
    /* padding: 50px 0; */
  }

  .categories_heading h1{
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1.5px;
    text-align: center;
    color: #000;
  }

  .categories_bottom{
    display: block;
    padding: 15px 15px;
    justify-content: none;

  }

  .categories_bottom_sub{
    display: flex;
    padding: 0 0px;
    margin-left: 20px;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .categories_bottom_sub .categories{
    padding: 20px 0px;
    margin-left: 0px;
    margin-top: 10px;
    width: 320px;
    height: 200px;
  }
  .categories img{
    width: 200px;
    height: 150px;
    object-fit: cover;
  }
}

@media screen and (max-width: 599px){
  .categories_main{
    padding-top: 30px;
    /* padding: 50px 0; */
  }

  .categories_heading h1{
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1.5px;
    text-align: center;
    color: #000;
  }

  .categories_bottom{
    display: block;
    padding: 15px 15px;
    justify-content: none;

  }

  .categories_bottom_sub{
    display: flex;
    padding: 0 0px;
    margin-left: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .categories_bottom_sub .categories{
    padding: 0 0px;
    margin-left: 0px;
    margin-top: 10px;
  }
  .categories img{
    width: 140px;
    height: 105px;
    object-fit: cover;
  }
}