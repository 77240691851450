.about_main{
  padding: 50px;
}

.about_heading{
  font-size: 20px;
  text-align: center;
}

.about_heading h1{
  font-size: 30px;
}

.about_bottom{
  padding: 30px 60px;
}

.about_bottom p{
  text-align: justify;
}
@media screen and (max-width: 750px) {
  .about_bottom{
    padding: 20px 5px;
  }
}