.all_store_main{
  padding-top: 30px;
  padding: 50px 0;
  background: #fff;
}


.all_store_heading{
  text-align: center;
  color: #000;
  padding: 0px 60px;
}


.all_store_heading h1{
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
}

.all_store_bottom{
  display: flex;
  padding: 40px 47px;
}
.all_store_bottom_sub{
  display: flex;
  justify-content: space-between;
}

.all_store_bottom_sub .store{
  padding: 0 15px;
}

.all_store{
  background-color: #fff;
  border: solid 1px #f4f4f4;
  border-radius: 10px;
  width: 300px;
  height: 135px;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.03);
  transition: 0.5s;
}

.all_store:hover{
  border: 2px solid #ffc200;
}

.all_store img{
  width: 200px;
  height: 90px;
  margin-top: 20px;
}

.myntra{
  width: 170px !important;
  object-fit: cover;
  margin-top: 20px !important;
}

.tata_cliq{
  width: 140px !important;
  height: 60px !important;
  margin-top: 15px !important;
}

.store{
  text-decoration: none;
  color: #000;
}

.AbeBooks{
  width: 170px !important;
  height: 100px !important;
}

.Appsumo{
  padding-top: 30px;
  width: 260px !important;
  height: 65px !important;
}

.MasterClass{
  width: 200px !important;
  height: 110px !important;
}

.Knowlegehut{
  width: 200px !important;
  height: 70px !important;
  margin-top: 30px !important;
}

.Gonoise{
  height: 120px !important;
  width: 180px !important ;
}

.Oneplus{
  height: 100px !important;
  width: 100px !important;
}

.BodyPedia{
  width: 200px !important;
  height: 90px !important;
}

.UPERFECT{
  width: 100px !important;
  height: 100px !important;
}

.Martinic{
  width: 190px !important;
  height: 50px !important;
  margin-top: 40px !important;
}

.Dominos{
  width: 240px !important;
  height: 70px !important;
  margin-top: 30px !important;
}

.Accor_Hotels{
  width: 170px !important;
  height: 100px !important;
}

.Emirates{
  width: 100px !important;
  height: 110px !important;
}

.Expedia{
  width: 200px !important;
  height: 50px !important;
  margin-top: 50px !important;
}

.cello{
  width: 100px !important;
  height: 100px !important;
}

.Pocket52{
  background: #000000;
}

.panda{
  width: 150px !important;
  height: 100px !important;
}


@media screen and (max-width: 599px){
  .all_store_main{
    padding-top: 30px;
    /* padding: 50px 0; */
    background: #fafafa;
  }

  .all_store_heading{
    text-align: center;
    color: #000;
    padding: 0px 30px;
  }

  .all_store_heading h1{
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 1px;
  }

  .all_store_bottom{
    display: block;
    /* justify-content: space-between; */
    padding: 0px 5px;
  }

  .all_store_bottom_sub{
    display: flex;
    padding: 0 0px;
    padding-left: 25px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .all_store_bottom_sub .store{
    padding: 0 0px;
    margin-right: 30px;
  }

  .all_store{
    background-color: #fff;
    border-radius: 10px;
    width: 160px;
    height: 75px;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border: 1px solid #b7b7b755;
    transition: 0.5s;
    margin-top: 20px;
  }

  .all_store:hover{
    border: 1px solid #ffc200;
  }

  .all_store img{
    width: 100px;
    height: 40px;
    margin-top: 15px;
  }

  .myntra{
    width: 140px !important;
    object-fit: cover;
    margin-top: 20px !important;
  }

  .tata_cliq{
    width: 140px !important;
    height: 60px !important;
    margin-top: 15px !important;
  }

  .store{
    text-decoration: none;
    color: #000;
  }

  .AbeBooks{
    width: 80px !important;
    height: 40px !important;
  }

  .Appsumo{
    padding-top: 0px;
    width: 80px !important;
    height: 35px !important;
  }

  .MasterClass{
    width: 60px !important;
    height: 30px !important;
    margin-top: 20px !important;
  }

  .Knowlegehut{
    width: 100px !important;
    height: 30px !important;
    margin-top: 20px !important;
  }

  .Gonoise{
    height: 45px !important;
    width: 60px !important ;
  }

  .Oneplus{
    height: 40px !important;
    width: 40px !important;
  }

  .BodyPedia{
    width: 100px !important;
    height: 40px !important;
  }
  .Boult{
    height: 20px !important;
    margin-top: 25px !important;
  }
  .UPERFECT{
    width: 50px !important;
    height: 50px !important;
  }

  .Martinic{
    width: 100px !important;
    height: 20px !important;
    margin-top: 25px !important;
  }

  .Dominos{
    width: 100px !important;
    height: 30px !important;
    margin-top: 18px !important;
  }
  .Accor_Hotels{
    width: 70px !important;
    height: 50px !important;
    margin-top: 10px !important;
  }
  .Emirates{
    width: 50px !important;
    height: 60px !important;
    margin-top: 5px !important;
  }
  .Expedia{
    width: 140px !important;
    height: 30px !important;
    margin-top: 20px !important;
  }

  .cello{
    width: 50px !important;
    height: 50px !important;
    margin-top: 12px !important;
  }

  .panda{
    margin-top: 10px !important;
    width: 80px !important;
    height: 50px !important;
  }

  .gucci{
    margin-top: 2px !important;
    width: 110px !important;
    height: 65px !important;
  }

}

@media screen and (min-width: 751px) and (max-width: 998px){
  .all_store{
    background-color: #fff;
    border-radius: 10px;
    width: 330px;
    height: 115px;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border: 1px solid #b7b7b755;
    transition: 0.5s;
    margin-top: 20px;
  }
  .all_store_bottom{
    display: block;
    /* justify-content: space-between; */
    padding: 0px 5px;
  }

  .all_store_bottom_sub{
    display: flex;
    padding: 0 0px;
    padding-left: 25px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .all_store_bottom_sub .store{
    padding: 0 0px;
    margin-right: 30px;
  }
}
@media screen and (min-width: 600px) and (max-width: 750px){
  .all_store{
    background-color: #fff;
    border-radius: 10px;
    width: 230px;
    height: 115px;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border: 1px solid #b7b7b755;
    transition: 0.5s;
    margin-top: 20px;
  }
  .all_store_bottom{
    display: block;
    /* justify-content: space-between; */
    padding: 0px 5px;
  }

  .all_store_bottom_sub{
    display: flex;
    padding: 0 0px;
    padding-left: 25px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .all_store_bottom_sub .store{
    padding: 0 0px;
    margin-right: 30px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1200px){
  .all_store{
    background-color: #fff;
    border-radius: 10px;
    width: 400px;
    height: 200px;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border: 1px solid #b7b7b755;
    transition: 0.5s;
    margin-top: 20px;
  }

  .all_store_bottom{
    display: block;
    /* justify-content: space-between; */
    padding: 0px 0px;
    margin-top: 20px;
  }

  .all_store_bottom_sub{
    display: flex;
    padding: 0 0px;
    padding-left: 120px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
  }
  .all_store_bottom_sub .store{
    padding: 0 0px;
    margin-right: 120px;
  }

}