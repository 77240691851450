.popup{
  top: 5%;
  left: 35%;
  position: fixed;
  background: #fafafa;
  display: flex;
  width: 400px;
  /* height: 500px; */
  justify-content: center;
  border-radius: 10px;
  align-items: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
  rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.popup-inner{
  position: relative;
  width: 400px;
  border-radius: 10px;
  /* height: 800px; */
  padding: 32px;
  width: 100%;
  /* max-width: 640px; */
  background: #fff;
}

.popup-inner .close-btn{
  position: absolute;
  top: 10px;
  right: 16px;
  border: none;
  outline: none;
  background: #fff;
}

.mobile_btn{
  width: 70px;
  height: 70px;
  background: #ffc100;
  border-radius: 50px;
  border: none;
  outline: none;
}

.mobile_btn i{
  font-size: 35px;
}

.popup_inner_main{
  padding: 10px 2px;
}

.popup_main{
  position: fixed;
  right: 30px;
  bottom: 30px;
}

.popup_header{
  display: flex;
  justify-content: space-between;
}

.logo_popup{
  display: flex;
  margin-bottom: 20px;
}

.logo_popup img{
  width: 70px;
  height: 70px;
}

.logo_popup h1{
  padding-top: 25px;
  font-size: 20px;
}

.popup_mobile i{
  font-size: 45px;
  padding-top: 15px;
}

.popup_greater_sign i{
font-size: 35px;
padding-top: 20px;
}

.popup_bottom h1{
  font-size: 18px;
  margin: 40px 0;
}

.popup_bottom img{
  width: 300px;
  margin-bottom: 20px;
}


@media screen and (max-width: 520px){
  .popup{
    top: 15%;
    left: 13% !important;
    position: fixed;
    background: #fafafa;
    display: flex;
    width: 400px;
    height: 500px;
    justify-content: center;
    border-radius: 10px;
    align-items: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .popup_header{
    display: none;
    justify-content: space-between;
  }

  .popup_bottom h1{
    font-size: 18px;
    margin: 40px 0;
    display: none;
  }

}

@media screen and (max-width: 480px){
  .popup{
    top: 15%;
    left: 13% !important;
    position: fixed;
    background: #fafafa;
    display: flex;
    width: 300px;
    height: 500px;
    justify-content: center;
    border-radius: 10px;
    align-items: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .popup_bottom img{
    width: 200px;
    margin-bottom: 20px;
  }

  .popup_header{
    display: none;
    justify-content: space-between;
  }

  .popup_bottom h1{
    font-size: 18px;
    margin: 40px 0;
    display: none;
  }

}