.faq_main{
  margin-top: 50px;
  padding: 50px 0;
}

.faq_heading h1{
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
  color: #000;
  margin-bottom: 40px;
}

.faq_bottom{
  display: flex;
  justify-content: space-between;
  padding: 20px 100px;
}

.faq{
  padding: 24px;
  width: 645px;
  height: 282px;
  background: #fafafa;
  border-radius: 5px;
}

.faq h1{
  font-size: 20px;
  margin-bottom: 20px;
}

.faq p{
  color: #313435;
  font-size: 16px;
}

@media screen and (max-width: 480px){
  .faq_heading h1{
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 1px;
    text-align: center;
    color: #000;
    margin-bottom: 40px;
  }
  .faq_bottom{
    display: block;
    justify-content: space-between;
    padding: 0px 20px;
  }

  .faq{
    padding: 24px;
    width: 390px;
    height: auto;
    background: #fafafa;
    border-radius: 5px;
    margin-bottom: 30px;
  }

  .faq h1{
    font-size: 20px;
    margin-bottom: 20px;
  }

  .faq p{
    color: #313435;
    font-size: 16px;
  }
}