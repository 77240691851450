.trending_store_main{
  padding-top: 30px;
  padding: 50px 0;
  background: #fafafa;
}

.trending_store_main-bg{
  background: #fff !important;
}

.trending_store_heading{
  text-align: center;
  color: #000;
}

.trending_store_heading h1{
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 1.5px;
}

.trending_store_bottom{
  display: flex;
  padding: 40px 47px;
}
.trending_store_bottom_sub{
  display: flex;
  justify-content: space-between;
}

.trending_store_bottom_sub .store{
  padding: 0 15px;
}

.trending_store{
  background-color: #fff;
  border: solid 1px #f4f4f4;
  border-radius: 10px;
  width: 300px;
  height: 135px;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.03);
  transition: 0.5s;
}

.trending_store:hover{
  border: 2px solid #ffc200;
}

.trending_store img{
  width: 250px;
  height: 100px;
  margin-top: 15px;
}

.myntra{
  width: 170px !important;
  object-fit: cover;
  margin-top: 20px !important;
}

.tata_cliq{
  width: 140px !important;
  height: 60px !important;
  margin-top: 15px !important;
}

.store{
  text-decoration: none;
  color: #000;
}



.view_all{
  text-align: center;
  margin-top: 60px;
}

.view_all a{
  text-decoration: none;
}

.View_all_btn{
  color: #000;
  background: #ffcc01;
  padding: 10px 20px;
  border-radius: 5px;
}

.view_all_link{
  text-decoration: none;
}


@media screen and (max-width: 998px){
  .trending_store_main{
    padding-top: 30px;
    /* padding: 50px 0; */
    background: #fafafa;
  }

  .trending_store_heading{
    text-align: center;
    color: #000;
  }

  .trending_store_heading h1{
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1.5px;
  }

  .trending_store_bottom{
    display: block;
    /* justify-content: space-between; */
    padding: 0px 0px;
  }

  .trending_store_bottom_sub{
    display: flex;
    padding: 0 0px;
    padding-left: 60px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .trending_store_bottom_sub .store{
    padding: 0 0px;
    margin-right: 60px;
  }

  .trending_store{
    background-color: #fff;
    border-radius: 10px;
    width: 200px;
    height: 100px;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border: 1px solid #b7b7b755;
    transition: 0.5s;
    margin-top: 20px;
  }

  .trending_store:hover{
    border: 1px solid #ffc200;
  }

  .trending_store img{
    width: 150px;
    height: 60px;
    margin-top: 20px;
  }

  .myntra{
    width: 140px !important;
    object-fit: cover;
    margin-top: 20px !important;
  }

  .tata_cliq{
    width: 140px !important;
    height: 60px !important;
    margin-top: 15px !important;
  }

  .store{
    text-decoration: none;
    color: #000;
  }
}



@media screen and (min-width: 1000px) and (max-width: 1200px){
  .trending_store_main{
    padding-top: 30px;
    /* padding: 50px 0; */
    background: #fafafa;
  }

  .trending_store_heading{
    text-align: center;
    color: #000;
  }

  .trending_store_heading h1{
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1.5px;
  }

  .trending_store_bottom{
    display: block;
    /* justify-content: space-between; */
    padding: 0px 0px;
    margin-top: 20px;
  }

  .trending_store_bottom_sub{
    display: flex;
    padding: 0 0px;
    padding-left: 120px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
  }

  .trending_store_bottom_sub .store{
    padding: 0 0px;
    margin-right: 120px;
  }

  .trending_store{
    background-color: #fff;
    border-radius: 10px;
    width: 400px;
    height: 200px;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border: 1px solid #b7b7b755;
    transition: 0.5s;
    margin-top: 20px;
  }

  .trending_store:hover{
    border: 1px solid #ffc200;
  }

  .trending_store img{
    width: 300px;
    height: 120px;
    margin-top: 40px;
  }

  .myntra{
    width: 140px !important;
    object-fit: cover;
    margin-top: 20px !important;
  }

  .tata_cliq{
    width: 140px !important;
    height: 60px !important;
    margin-top: 15px !important;
  }

  .store{
    text-decoration: none;
    color: #000;
  }
}

@media screen and (min-width: 600px) and (max-width: 750px){
  .trending_store_main{
    padding-top: 30px;
    /* padding: 50px 0; */
    background: #fafafa;
  }

  .trending_store_heading{
    text-align: center;
    color: #000;
  }

  .trending_store_heading h1{
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1.5px;
  }

  .trending_store_bottom{
    display: block;
    /* justify-content: space-between; */
    padding: 0px 5px;
  }

  .trending_store_bottom_sub{
    display: flex;
    padding: 0 0px;
    padding-left: 25px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .trending_store_bottom_sub .store{
    padding: 0 0px;
    margin-right: 30px;
  }

  .trending_store{
    background-color: #fff;
    border-radius: 10px;
    width: 230px;
    height: 115px;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border: 1px solid #b7b7b755;
    transition: 0.5s;
    margin-top: 20px;
  }

  .trending_store:hover{
    border: 1px solid #ffc200;
  }

  .trending_store img{
    width: 150px;
    height: 60px;
    margin-top: 25px;
  }

  .myntra{
    width: 140px !important;
    object-fit: cover;
    margin-top: 20px !important;
  }

  .tata_cliq{
    width: 140px !important;
    height: 60px !important;
    margin-top: 15px !important;
  }

  .store{
    text-decoration: none;
    color: #000;
  }
}
@media screen and (min-width: 751px) and (max-width: 998px){
  .trending_store_main{
    padding-top: 30px;
    /* padding: 50px 0; */
    background: #fafafa;
  }

  .trending_store_heading{
    text-align: center;
    color: #000;
  }

  .trending_store_heading h1{
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1.5px;
  }

  .trending_store_bottom{
    display: block;
    /* justify-content: space-between; */
    padding: 0px 5px;
  }

  .trending_store_bottom_sub{
    display: flex;
    padding: 0 0px;
    padding-left: 25px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .trending_store_bottom_sub .store{
    padding: 0 0px;
    margin-right: 30px;
  }

  .trending_store{
    background-color: #fff;
    border-radius: 10px;
    width: 330px;
    height: 115px;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border: 1px solid #b7b7b755;
    transition: 0.5s;
    margin-top: 20px;
  }

  .trending_store:hover{
    border: 1px solid #ffc200;
  }

  .trending_store img{
    width: 150px;
    height: 60px;
    margin-top: 25px;
  }

  /* .myntra{
    width: 140px !important;
    object-fit: cover;
    margin-top: 20px !important;
  } */

  .tata_cliq{
    width: 140px !important;
    height: 60px !important;
    margin-top: 15px !important;
  }

  .store{
    text-decoration: none;
    color: #000;
  }
}

@media screen and (max-width: 599px){
  .trending_store_main{
    padding-top: 30px;
    /* padding: 50px 0; */
    background: #fafafa;
  }

  .trending_store_heading{
    text-align: center;
    color: #000;
  }

  .trending_store_heading h1{
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1.5px;
  }

  .trending_store_bottom{
    display: block;
    /* justify-content: space-between; */
    padding: 0px 5px;
  }

  .trending_store_bottom_sub{
    display: flex;
    padding: 0 0px;
    padding-left: 25px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .trending_store_bottom_sub .store{
    padding: 0 0px;
    margin-right: 30px;
  }

  .trending_store{
    background-color: #fff;
    border-radius: 10px;
    width: 160px;
    height: 75px;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border: 1px solid #b7b7b755;
    transition: 0.5s;
    margin-top: 20px;
  }

  .trending_store:hover{
    border: 1px solid #ffc200;
  }

  .trending_store img{
    width: 100px;
    height: 40px;
    margin-top: 15px;
  }

  .myntra{
    width: 140px !important;
    object-fit: cover;
    margin-top: 20px !important;
  }

  .tata_cliq{
    width: 140px !important;
    height: 60px !important;
    margin-top: 15px !important;
  }

  .store{
    text-decoration: none;
    color: #000;
  }
}