/* .footer_top{
  background: #fafafa;
  padding: 15px 60px;
  display: flex;
  justify-content: space-between;
}

.footer_top ul{
  display: flex;
  padding-top: 5px;
}

.footer_top ul li{
  list-style-type: none;
  margin-right: 25px;
}

.footer_top ul li a{
  text-decoration: none;
  color: #000;
  transition: 0.5s;
}

.web_name h2{
  color: #ffc100;
  letter-spacing: 1.5px;
  font-weight: 800;
}

.footer_top ul li a:hover{
  color: #ffc100;
}

.footer_bottom{
  background: #ffc100;
  width: 100%;
  text-align: center;
}

.footer_bottom p{
  padding-top: 10px;
  padding-bottom: 10px;
}

@media screen and (max-width: 480px){
  .footer_top{
    background: #fafafa;
    padding: 15px 60px;
    display: block;
    justify-content: space-between;
  }

  .footer_top ul{
    display: flex;
    padding-top: 5px;
  }

  .footer_top ul li{
    list-style-type: none;
    margin-right: 0px;
  }

  .footer_top ul li a{
    text-decoration: none;
    color: #000;
    font-size: 15px;
    transition: 0.5s;
  }

  .web_name h2{
    color: #ffc100;
    letter-spacing: 1.5px;
    font-weight: 800;
    font-size: 40px;
  }

  .footer_top ul li a:hover{
    color: #ffc100;
  }

  .footer_bottom{
    background: #ffc100;
    width: 100%;
    padding: 10px 0px;
    text-align: center;
  }
} */

.nav_ul li {
  padding: 0 0.6rem;
}

.nav_ul a {
  font-size: 1.05rem;
  color: #000 !important;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.btn1 {
  border: 0;
  padding: 0.5rem 1.8rem;
  color: #fff;
  background-color: #01193c;
}

.btn2 {
  border: 0;
  padding: 0.5rem 1.2rem;
  color: #fff;
  background-color: #ffc100;
}

.Footer {
  padding: 2rem 5rem;
  margin-top: 2.5rem;
  background: #fafafa;
}

.ft-1 h3 {
  font-weight: 700;
  letter-spacing: 2px;
  padding: 0.5rem 0;
}


.ft-1 span {
  color: #ffc100;
}

.ft-1 p {
  padding: 0rem 5rem 0.8rem 0;
  font-weight: 500;
}

.footer-icons img {
  /* padding: 0.4rem 0.5rem; */
  color: #ffc100;
  margin-right: 1.4rem;
  margin-bottom: 1.5rem;
  width: 30px;
}

.footer-icons i:hover {
  background: #f60838;
  color: #fff;
  transition: 0.6s;
  cursor: pointer;
}

.Footer h5 {
  color: #ffc100;
}

.ft-2 ul {
  list-style: none;
  padding-left: 0;
}

.ft-2 ul li {
  padding: 0.35rem 0;
  font-weight: 500;
}

.ft-2 ul a {
  text-decoration: none;
  color: #000;
  font-size: 1.06rem;
}

.ft-3 p {
  font-weight: 500;
  padding: 0.1rem 0;
  font-size: 1.06rem;
}

.ft-3 p a{
  text-decoration: none;
  color: #000;
  transition: 0.5s;
}

.ft-3 p a:hover{
  color: #ffc100;
}

.ft-3 img {
  padding-right: 0.5rem;
}

.Last-footer {
  background: #ffc100;
  text-align: center;
  padding: 1rem 0;
}

.Last-footer p {
  margin-bottom: 0;
  font-size: 1.2rem;
  font-weight: 700;
}